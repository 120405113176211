import {createApp} from 'vue';
import App from '@/App.vue';
import store from '@/store';
import router from '@/router';
//UI库
import vant from 'vant';
import 'vant/lib/index.css';
//数据持久化
import StorageUtils from '@/utils/StorageUtils.js';

const app = createApp(App);
// app.config.globalProperties.$name = name;
app.config.globalProperties.$storage = StorageUtils;
app.use(store)
    .use(router)
    .use(vant)
    .mount('#app');
