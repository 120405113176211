import ClipboardJS from "clipboard";
import Toaster from "@/utils/Toaster";

const isEmpty = function (str) {
    return str == undefined || str === '' || str === 'undefined';
}
const hasKeys = function (object) {
    return !isEmpty(object) && Object.keys(object).length > 0;
}
const toHTML = function (str) {
    if (!isEmpty(str)) {
        return str.replace(/[\n]|[\t]|[ ]/g, function (val) {
            switch (val) {
                case '\n':
                    return '<br>'
                case ' ':
                    return '&nbsp'
                case '\t':
                    return '&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp'
            }
        });
    }
    return '';
}
const toText = function (str) {
    if (!isEmpty(str)) {
        return str.replace(/<br>|&nbsp/g, function (val) {
            switch (val) {
                case '<br>':
                    return '\n';
                case '&nbsp':
                    return ' ';
            }
        });
    }
    return '';
}
const toCopy = function (event, text) {
    const clipboardJS = new ClipboardJS(event.target, {
        text: () => text,
    });
    clipboardJS.on('success', e => {
        // console.log('success', e.text)
        Toaster.message('已复制');
        // 释放内存
        clipboardJS.destroy();
    });
    clipboardJS.on('error', e => {
        // console.log('error', e)
        Toaster.message('该浏览器不支持复制');
        // 释放内存
        clipboardJS.destroy();
    });
    clipboardJS.onClick(event);
}
export default {
    isEmpty,
    hasKeys,
    toHTML,
    toText,
    toCopy,
}