<template>
    <router-view v-slot="{ Component }">
        <keep-alive :include="getKeepAliveList">
            <component :is="Component"/>
        </keep-alive>
    </router-view>
    <!--  <router-view></router-view>-->
</template>

<script>
// import VConsole from "vconsole";
import KeepAliver from "@/utils/KeepAliver";

export default {
    name: 'App',
    setup() {
        // var vConsole = new VConsole();
        // console.log(SessionStore.getState());
        KeepAliver.onRefresh();
    },
    computed: {
        getKeepAliveList() {
            // console.log(this.$store.state.keepAliveList);
            // return [];
            return this.$store.getters.getData['keepAliveList'];
        }
    },
    beforeCreate() {
        // console.log('beforeCreate');
    },
    created() {
        // console.log('created');
    },
    beforeMount() {
        // console.log('beforeMount');
    },
    mounted() {
        // console.log('mounted');
    },
    beforeUpdate() {
        // console.log('beforeUpdate');
    },
    updated() {
        // console.log('updated');
    },
    beforeUnmount() {
        // console.log('beforeUnmount');
    },
    unmounted() {
        // console.log('unmounted')
    },
}
</script>

<style lang="scss">
@import './common/style.css';

#app {
    display: flex;
    flex-direction: column;
    width: 100vw;
    max-width: 100vw;
    height: 100vh;
    max-height: 100vh;
    overflow: hidden;
    box-sizing: border-box;
    background-color: #F5F5F5;
    //background-color: red;
    color: #333333;
    // color: green;
    font-size: 14px;
}
</style>
