import {createRouter, createWebHashHistory} from 'vue-router'
import StorageUtils from "@/utils/StorageUtils";

const routes = [
    {
        path: '/',
        redirect: '/manage_login',
        // redirect: '/test',
    },
    {
        path: '/manage_login',
        name: '管理系统',
        component: () => import('@/views/manage/ManageLogin'),
    },
    {
        path: '/xsm_center',
        name: '管理中心',
        component: () => import('@/views/manage/XsmCenter'),
    },
    {
        path: '/xsm_examine_list',
        name: '商家审核列表',
        component: () => import('@/views/manage/XsmExamineList'),
    },
    {
        path: '/xsm_examine_details',
        name: '商家审核详情',
        component: () => import('@/views/manage/XsmExamineDetails'),
    },
    {
        path: '/xsm_work_type',
        name: '工作分类',
        component: () => import('@/views/manage/XsmWorkType'),
    },
    {
        path: '/xsm_task_list',
        name: '商家发单列表',
        component: () => import('@/views/manage/XsmTaskList'),
    },
    {
        path: '/xsm_task_details',
        name: '商家发单详情',
        component: () => import('@/views/manage/XsmTaskDetails'),
    },
    {
        path: '/xsm_user_list',
        name: '招工详情',
        component: () => import('@/views/manage/XsmUserList'),
    },
    {
        path: '/xsm_user_details',
        name: '用户详情',
        component: () => import('@/views/manage/XsmUserDetails'),
    },
    {
        path: '/xsm_order_list',
        name: '用户接单列表',
        component: () => import('@/views/manage/XsmOrderList'),
    },
    {
        path: '/xsm_sys_config',
        name: '参数配置',
        component: () => import('@/views/manage/XsmSysConfig'),
    },
    {
        path: '/xsm_explain_list',
        name: '说明列表',
        component: () => import('@/views/manage/XsmExplainList'),
    },
    {
        path: '/xsm_edit_explain',
        name: '编辑说明',
        component: () => import('@/views/manage/XsmEditExplain'),
    },
    {
        path: '/xsm_pay_order_list',
        name: '未打款列表',
        component: () => import('@/views/manage/XsmPayOrderList'),
    },
    {
        path: '/lss_center',
        name: '亮伞伞',
        component: () => import('@/views/manage/LssCenter'),
    },
    {
        path: '/lss_order_list',
        name: '订单列表',
        component: () => import('@/views/manage/LssOrderList'),
    },
    {
        path: '/lss_order_details',
        name: '订单明细',
        component: () => import('@/views/manage/LssOrderDetails'),
    },
    {
        path: '/lss_order_preview',
        name: '订单预览',
        component: () => import('@/views/manage/LssOrderPreview'),
    },
    {
        path: '/lss_order_add',
        name: '新增订单',
        component: () => import('@/views/manage/LssOrderAdd'),
    },
    {
        path: '/lss_order_edit',
        name: '订单编辑',
        component: () => import('@/views/manage/LssOrderEdit'),
    },
    {
        path: '/lss_activity_list',
        name: '活动列表',
        component: () => import('@/views/manage/LssActivityList'),
    },
    {
        path: '/lss_activity_edit',
        name: '活动编辑',
        component: () => import('@/views/manage/LssActivtiyEdit'),
    },
    {
        path: '/lss_worker_list',
        name: '师傅列表',
        component: () => import('@/views/manage/LssWorkerList'),
    },
    {
        path: '/lss_worker_edit',
        name: '师傅信息',
        component: () => import('@/views/manage/LssWorkerEdit'),
    },
    {
        path: '/xsm_cooperation_list',
        name: '商务合作列表',
        component: () => import('@/views/manage/XsmCooperationList'),
    },
    {
        path: '/information_details',
        name: '信息详情',
        component: () => import('@/views/manage/InformationDetails'),
    },
    {
        path: '/test',
        name: '测试',
        component: () => import('@/views/h5/Test'),
    },
]
const router = createRouter({
    history: createWebHashHistory(),
    routes
})
//路由守卫
router.beforeEach((to, from, next) => {
    // console.log('beforeEach');
    // console.log('from:', from.path);
    // console.log('to:', to.path);
    // console.log('isLogin:', StorageUtils.isLogin());
    //已登录
    if (StorageUtils.isLogin()) {
        if (to.path === '/manage_login') {
            next(false);
        } else {
            next();
        }
    } else {
        //未登录
        if (to.path === '/manage_login') {
            next();
        } else {
            next('/manage_login');
        }
    }
    // next();
})
router.afterEach((to, from) => {
    // console.log('afterEach');
    // console.log('from:', from.path);
    // console.log('to:', to.path);
    // window.document.title = to.name ? to.name : '闲时忙';
    window.document.title = '闲时忙';
})
export default router