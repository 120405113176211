import store from "@/store"
import TextUtils from "@/utils/TextUtils";

const setData = function (key, value, flag = true) {
    store.commit('setData', {key, value});
    if (flag) {
        // localStorage.setItem(key, JSON.stringify(value));
        sessionStorage.setItem(key, JSON.stringify(value));
    }
}
const getData = function (key, defVal) {
    let value = store.getters.getData[key];
    if (TextUtils.isEmpty(value)) {
        let jsonStr = sessionStorage.getItem(key);
        value = TextUtils.isEmpty(jsonStr) ? defVal : JSON.parse(jsonStr);
        // console.log('getData', value);
        setData(key, value, false);
    }
    return value;
}
const setRouterInfo = function (info) {
    setData("ROUTER_INFO", info);
}
const getRouterInfo = function () {
    return getData("ROUTER_INFO");
}
const setLoginInfo = function (info = '') {
    setData('LOGIN_INFO', info);
}
const getLoginInfo = function () {
    return getData('LOGIN_INFO');
}
const getUID = function () {
    return getLoginInfo() ? getLoginInfo().u_id : 0;
}
const isLogin = function () {
    return getLoginInfo() ? true : false;
}
const setUserInfo = function (info) {
    setData('USER_INFO', info);
}
const getUserInfo = function () {
    return getData('USER_INFO');
}
const getAppID = function () {
    return getData('APP_ID');
}
const isDebug = function () {
    return getData('APP_DEBUG');
}
const isTest = function () {
    return getData('APP_TEST');
}
export default {
    setData,
    getData,
    setRouterInfo,
    getRouterInfo,
    //
    setLoginInfo,
    getLoginInfo,
    getUID,
    isLogin,
    setUserInfo,
    getUserInfo,
    //
    getAppID,
    isTest,
    isDebug,
}