import {showToast, showLoadingToast, showSuccessToast, showFailToast, closeToast} from 'vant';
import Timer from "@/utils/Timer";

/**
 * 加载提示
 * @param message 提示信息
 * @param duration 显示时长(0:toast不会消失)
 * @param forbidClick 是否禁止背景点击(true:禁止点击)
 */
const loading = (message = '请稍等...', duration = 0, forbidClick = true) => {
    showLoadingToast({
        message,
        duration,
        forbidClick,
    });
}
const close = () => {
    closeToast();
}
const message = (message) => {
    showToast(message);
}
const success = (message = '成功') => {
    showSuccessToast(message);
}
const onSuccess = (callback, message = '成功', duration = 2000) => {
    showSuccessToast({
        message,
        duration,
        forbidClick: true,
    });
    Timer.onFinish(callback, duration);
}
const fail = (message = '失败') => {
    showFailToast(message);
}
export default {
    message,
    loading,
    success,
    onSuccess,
    fail,
    close,
}
