import {createStore} from 'vuex'

export default createStore({
    state: {
        // APP_TEST: true,//测试
        // APP_DEBUG: true,
        APP_TEST: false,//正式
        APP_DEBUG: false,
        // APP_ID: 'wxaece952425918d26',//闲时忙
        APP_ID: 'wx64320be5bc40b27c',//亮伞伞
        keepAliveList: [],
    },
    mutations: {
        setData(state, info) {
            state[info.key] = info.value;
        },
    },
    getters: {
        getData(state) {
            return state;
        },
    },
    actions: {},
    modules: {},
})
