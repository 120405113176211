let timer;
/**
 * 开始运行
 * @param onRunning 运行时回调
 * @param intervalTime 间隔时间（毫秒）
 */
const run = (onRunning, intervalTime = 1000) => {
    // console.log('run', intervalTime);
    if (onRunning) {
        stop();
        timer = setInterval(onRunning, intervalTime);
    }
}
/**
 * 立即开始
 * @param onRunning 运行时回调
 * @param runTime 运行次数
 * @param intervalTime 间隔时间（毫秒）
 */
const start = (onRunning, runTime = 1, intervalTime) => {
    if (onRunning) {
        onRunning(runTime);
        run(() => {
            onRunning(--runTime);
            // console.log(runTime);
            if (runTime < 1) {
                stop();
            }
        }, intervalTime);
    }
}
/**
 * 计时结束
 * @param callback 结束时回调
 * @param intervalTime 间隔时间（毫秒）
 */
const onFinish = (callback, intervalTime) => {
    if (callback) {
        run(() => {
            callback();
            stop();
        }, intervalTime);
    }
}
const stop = () => {
    if (timer) {
        clearInterval(timer);
        timer = null;
    }
}
export default {
    run,
    start,
    onFinish,
    stop,
}
