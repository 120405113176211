import store from "@/store"

const onHandle = function (from, to) {
    // console.log('from:', from);
    // console.log('to:', to);
    // console.log('from:', from.name);
    // console.log('to:', to.name);
    switch (from.name) {
        case '订单列表':
            if (to.name === '订单明细') {
                addView('LssOrderList');
            }
            if (to.name === '管理中心') {
                delView('LssOrderList');
            }
            break;
    }
}
const getList = function () {
    return store.getters.getData['keepAliveList'];
}
const setList = function (keepAliveList) {
    store.commit('setData', {key: 'keepAliveList', value: keepAliveList})
    sessionStorage.setItem('keepAliveList', JSON.stringify(keepAliveList));
    // console.log(getList());
}
/**
 * 是否存在活动组件
 * @param name 组件名称
 * @returns {boolean} true 有
 */
const hasView = function (name) {
    return getList().indexOf(name) !== -1;
}
/**
 * 添加活动组件
 * @param name
 */
const addView = function (name) {
    if (!hasView(name)) {
        let keepAliveList = getList();
        keepAliveList.push(name);
        setList(keepAliveList);
    }
}
/**
 * 删除活动组件
 * @param name
 */
const delView = function (name) {
    if (hasView(name)) {
        let keepAliveList = getList();
        keepAliveList.splice(keepAliveList.indexOf(name), 1);
        setList(keepAliveList);
    }
}
const onRefresh = function () {
    let keepAliveList = sessionStorage.getItem("keepAliveList");
    // console.log(keepAliveList);
    if (keepAliveList) {
        store.commit('setData', {key: 'keepAliveList', value: JSON.parse(keepAliveList)});
    }
}
export default {
    onHandle,
    onRefresh,
}